import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home'
import ComingSoon from './ComingSoon';
import SignUp from './SignUp';
import LogIn from './LogIn';
import ForgotPassword from './ForgotPassword';
import ResetEmailSent from './ResetEmailSent';
import PasswordReset from './PasswordReset'


function App() {
  // Define a state to store the fetched data
  const [data, setData] = useState('no data');

  useEffect(() => {
    // Fetch data from the Flask API
    fetch('https://chase-fluency-webapp.onrender.com/api/data')
      .then(response => response.json())
      .then(data => setData(data)) // Store the fetched data in the state
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />  
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/log-in" element={<LogIn />} />
        <Route path="/log-in/forgot-password" element={<ForgotPassword />} />
        <Route path="/log-in/forgot-password/reset-email-sent" element={<ResetEmailSent />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        {/* Add more routes as needed */}
      </Routes>

    </div>
  );
}

export default App;
